import { RouteNames } from '@/enums/routes/RouteNames';
import { URLParameters } from '@/interfaces/components/customSearch/URLParameters';
import User from '@/models/User';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { generateAndStoreSearchParameters } from '@/helpers/SearchHelper';
import router from '@/router';
import { capitalizeWord } from '@/helpers/TranslationHelper';
import { CustomSearchParameters } from '@/interfaces/components/customSearch/CustomSearchParameters';
import { FilterResponse } from '@/interfaces/components/customSearch/FilterResponse';
import { QueryParams } from '@/interfaces/components/customSearch/QueryParams';
import { notification } from 'ant-design-vue';
import i18n from '@/i18n';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';

export function verifyURLParameters(urlParameters: URLParameters) {
    try {
        isEntityTypeProjectClientOrDashboard(urlParameters.entityType);
        doesTokenExist(urlParameters.token);
        doOtherQueryParamsExist(urlParameters.restOfQueryParams, urlParameters.entityType);
    } catch (e) {
        throw e;
    }
}

export async function tryToSetToken(token: string) {
    try {
        await User.setToken({
            data: {
                access_token: token,
            },
        });
    } catch (e) {
        throw e;
    }
}

function isEntityTypeProjectClientOrDashboard(entityType: string) {
    if (entityType !== 'project' && entityType !== 'client' && entityType !== 'dashboard') {
        throw new Error('Invalid entity type');
    }
}

function doesTokenExist(token: string | undefined) {
    if (token == null) {
        throw new Error('Missing token');
    }
}

function doOtherQueryParamsExist(restOfQueryParams: QueryParams, entityType?: string) {
    if (Object.values(restOfQueryParams).length <= 0 && entityType !== 'dashboard') {
        throw new Error('Other query params must be defined');
    }
}

export function verifyUserId(userId: string | undefined) {
    if (userId == null) {
        throw new Error('User ID does not exist');
    }
}

export async function fetchResultsAndNavigateToRoute(
    urlFilters: string,
    restOfQueryParams: QueryParams,
    userId: string,
    entityType: string
) {
    let results = [];
    let activitiesFilterYieldedResults = false;
    const shouldSearchInActivities = false; // with the current filters,
    // it's impossible activities should yield any results

    if (isEntityTypeDashboard(entityType)) {
        router.push({
            name: RouteNames.statisticsDashboard,
        });

        return;
    }

    const filterApiRoute = isEntityTypeProjects(entityType)
        ? ApiRoutes.projects.filterProjects
        : ApiRoutes.client.filterClients;

    try {
        results = await fetchFilteredResults(urlFilters, filterApiRoute);
        if (shouldSearchInActivities && isEntityTypeProjects(entityType) && results.length <= 0) {
            results = await fetchFilteredResults(urlFilters, ApiRoutes.nextStep.filterNextStepProjects);
            activitiesFilterYieldedResults = results.length > 0;
        }
    } catch (e) {
        throw e;
    }

    navigateToCorrectRouteAndSaveParametersToLocalStorage(
        {
            results,
            restOfQueryParams,
            userId,
            entityType,
        },
        activitiesFilterYieldedResults
    );
}

async function fetchFilteredResults(urlFilters: string, filterApiRoute: (query: string) => string) {
    let results = null;
    try {
        urlFilters = encodeURIComponent(urlFilters);
        results = await ApiController.get(filterApiRoute(`&q=${urlFilters}`));
    } catch (e) {
        throw e;
    }

    return results.data as FilterResponse[];
}

function navigateToCorrectRouteAndSaveParametersToLocalStorage(
    customSearchParameters: CustomSearchParameters,
    activitiesFilterYieldedResults: boolean
) {
    const entityRouteName = isEntityTypeProjects(customSearchParameters.entityType)
        ? RouteNames.project
        : RouteNames.clientScreen;
    const defaultRouteName = isEntityTypeProjects(customSearchParameters.entityType)
        ? RouteNames.home
        : RouteNames.client;

    if (customSearchParameters.results.length <= 0) {
        if (isEntityTypeProjects(customSearchParameters.entityType)) {
            handleStoringProjectFilters(customSearchParameters, false, ProjectTypes.Lead as string);
        } else {
            handleStoringClientFilters(customSearchParameters);
        }

        notification.info({
            message: isEntityTypeProjects(customSearchParameters.entityType)
                ? (i18n.t('Nije pronađen niti jedan projekt s ovim filterom') as string)
                : (i18n.t('Nije pronađen niti jedan klijent s ovim filterom') as string),
            description: '',
        });
        router.push({
            name: defaultRouteName,
        });
    } else if (customSearchParameters.results.length === 1) {
        const params = {
            id: isEntityTypeProjects(customSearchParameters.entityType) ? customSearchParameters.results[0].id : '',
            clientId: !isEntityTypeProjects(customSearchParameters.entityType)
                ? customSearchParameters.results[0].id
                : '',
        };

        router.push({
            name: entityRouteName,
            params,
            query: {
                initialTab: String(
                    isEntityTypeProjects(customSearchParameters.entityType)
                        ? ProjectTabValues.Basic
                        : ClientTabs.CUSTOMER
                ),
            },
        });
    } else {
        if (isEntityTypeProjects(customSearchParameters.entityType)) {
            handleStoringProjectFilters(customSearchParameters, activitiesFilterYieldedResults, undefined);
        } else {
            handleStoringClientFilters(customSearchParameters);
        }
        router.push({
            name: defaultRouteName,
        });
    }
}

function handleStoringProjectFilters(
    customSearchParameters: CustomSearchParameters,
    activitiesFilterYieldedResults: boolean,
    activeTab: string | undefined
) {
    const projectsState = customSearchParameters.results.length > 0 ? customSearchParameters.results[0].state : '';

    Object.values(ProjectTypes).forEach((projectType) => {
        const shouldSetActiveTab = isProjectsStateEqualToCurrentProjectTypeIteration(
            projectsState,
            projectType as string,
            activitiesFilterYieldedResults,
            activeTab
        );

        generateAndStoreSearchParameters({
            type: projectType as string,
            query:
                // customSearchParameters.results.length > 0 ?
                generateQueryFromRestOfQueryParams(customSearchParameters.restOfQueryParams),
            // @ts-ignore
            currentUser: {
                id: customSearchParameters.userId,
            },
            shouldSetDefaultUser: customSearchParameters.results.length <= 0,
            shouldSetActiveTab,
        });
    });
}

function handleStoringClientFilters(customSearchParameters: CustomSearchParameters) {
    generateAndStoreSearchParameters({
        type: 'clients',
        query: generateQueryFromRestOfQueryParams(customSearchParameters.restOfQueryParams),
        // @ts-ignore
        currentUser: {
            id: customSearchParameters.userId,
        },
        shouldSetDefaultUser: false,
        shouldSetActiveTab: false,
    });
}

function generateQueryFromRestOfQueryParams(restOfQueryParams: QueryParams) {
    return Object.values(restOfQueryParams).join(' ');
}

function convertProjectStateNameToEnum(projectStateName: string) {
    const capitalizedProjectStateName = capitalizeWord(projectStateName) as keyof typeof ProjectTypes;
    return ProjectTypes[capitalizedProjectStateName] as keyof typeof ProjectTypes;
}

function isEntityTypeProjects(entityType: string) {
    return entityType === 'project';
}

function isEntityTypeDashboard(entityType: string) {
    return entityType === 'dashboard';
}

function isProjectsStateEqualToCurrentProjectTypeIteration(
    projectState: string,
    projectType: string,
    activitiesFilterYieldedResults: boolean,
    activeTab: string | undefined
) {
    return convertProjectStateNameToEnum(projectState) === projectType || projectType === activeTab;
}

export function generateArrayFromRestOfParams(restOfQueryParams: QueryParams) {
    return Object.entries(restOfQueryParams).map((entry) => {
        const key = entry[0];
        const shouldSurroundWithPercentages = key === 'name' || key === 'contactPersons.email' || key === 'connection';
        if (entry[1].charAt(0) === ' ') {
            entry[1] = '+' + entry[1].substring(1);
        }
        const value = shouldSurroundWithPercentages ? encodeURI(`%${entry[1]}%`) : entry[1];
        return `(${key}:${value})`;
    });
}
