
import { Vue, Component } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import {
    fetchResultsAndNavigateToRoute,
    generateArrayFromRestOfParams,
    tryToSetToken,
    verifyURLParameters,
    verifyUserId,
} from '@/helpers/CustomSearch/CustomSearchHelper';

@Component({
    name: 'CustomSearch',
})
export default class CustomSearch extends Vue {
    private get userId() {
        return this.$store.getters['jwtData/userId'];
    }

    private get entityType() {
        return this.$route.params.type;
    }

    private get token() {
        return this.$route.query.token;
    }

    private get restOfQueryParams() {
        const queryParams = Object.assign({}, this.$route.query);
        delete queryParams.token;
        return queryParams;
    }

    private get urlFilters() {
        return generateArrayFromRestOfParams(this.restOfQueryParams).join('^');
    }

    private verifyUsedId() {
        if (this.userId == null) {
            throw new Error('User ID does not exist');
        }
    }

    private async mounted() {
        try {
            verifyURLParameters({
                entityType: this.entityType,
                token: this.token as string,
                restOfQueryParams: this.restOfQueryParams,
            });
            await tryToSetToken(this.token as string);
            verifyUserId(this.userId);
            await fetchResultsAndNavigateToRoute(this.urlFilters, this.restOfQueryParams, this.userId, this.entityType);
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error((e as Error).message);
            this.$router.push({
                name: RouteNames.error,
            });

            return;
        }
    }
}
